

//testing
//const axios = require('axios')

//  export const post_add_wish_list = () => {
//   return axios.create({
//     headers: {
//       'access-token': 'access_token_2c4ab69bec0dee2d36023d8b3c0c803f197ef328'
//     }
//   }).post(`http://admin.sbbabyshop.com/testing/cors?name=Smith`)
//     .then(function (res) {
//         console.log(res)
//     })
//     .catch(function (error) {
//         console.log("Error")
//     })
// }

//post_add_wish_list()



const axios = require('axios')

export const post_add_wish_list = (product_id, variant_id, user_id, access_token) => {
  return axios.create({
    headers: {
        'access-token': access_token
    }
  }).post(`http://admin.sbbabyshop.com/wishlist/add?user_id=${user_id}&product_id=${product_id}&variant_id=${variant_id}`)
    .then(res => res.data.data)
    .catch(function (error) {
        console.log("Error", error)
    })
}
















//First
// const axios = require('axios')
// const config = require('./_config')

//  const post_add_wish_list = (product_id, variant_id, user_id, access_token) => {
//     return axios.create({
//         headers: {
//           'access-token': 'access_token_2c4ab69bec0dee2d36023d8b3c0c803f197ef328'
//         }
//       }).post(`http://admin.sbbabyshop.com/wishlist/add`)
//         .then(function (res) {
//             // return { data: res.data.data, status: 'success' }
//             console.log(res)
//         })
//         .catch(function (error) {
//             // return { error: error, status: 'fail' }
//             console.log('error')
//         })
// }

// post_add_wish_list()
