// npm module
import 'bootstrap/scss/bootstrap.scss'
// react module
import React, { Component } from 'react'
// custom module
import AppRoute from './routes/routes'

import './i18n'
// style
import './App.scss'

export default class App extends Component {
  render() {
    return (
      <main>
        <AppRoute />
      </main>
    )
  }
}
