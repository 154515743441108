import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {get_footer} from '../../controller'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'
export default function Footer() {
  const { t,i18n } = useTranslation();
  const lang = useSelector(state => state.lang)
  const [ footer, setfooter ] = useState(null)
  const [ phone, setphone ] = useState(null)
  const [ email, setemail ] = useState(null)
  
  useEffect(() => {
    get_footer({
      lang: lang
    })
    .then((res) => {
      setfooter(res)
      const phone = res[0].phone
      const email = res[0].email
      const phArray = phone.split(',')
      const mailArray = email.split(',')
      setphone(phArray)
      setemail(mailArray)
    })
    i18n.changeLanguage(lang)
  }, [setfooter, setphone, setemail])
  
  return (
    <footer className="footer">
      <article className="container">
        <section className="row">
          <div className='col-lg-4 info-content'>
            {
              footer !== null &&
              <>
                <img src={footer[0].delivery_icon} alt={footer[0].delivery_name}/>
                <div className="content">
                  <h5 className="title">{footer[0].delivery_name}</h5>
                  <p>{parse(footer[0].delivery_description)}</p>
                </div>
              </>
            }
          </div>
          <div className='col-lg-4 info-content'>
            {
              footer !== null &&
              <>
                <img src={footer[0].payment_icon} alt={footer[0].payment_name}/>
                <div className="content">
                  <h5 className="title">{footer[0].payment_name}</h5>
                  <p>{parse(footer[0].payment_description)}</p>
                </div>
              </>
            }
          </div>
          <div className='col-lg-4 info-content'>
            {
              footer !== null &&
              <>
                <img src={footer[0].customer_service_icon} alt={footer[0].customer_service_name}/>
                <div className="content">
                  <h5 className="title">{footer[0].customer_service_name}</h5>
                  <p>{parse(footer[0].cu_service_description)}</p>
                </div>
              </>
            }
          </div>
        </section>
      </article>
      <hr/>
      <article className="container section-contact">
        <section className="row">
          <div className="col-12">
            <h3>Contact Infomation</h3>
          </div>
          <div className="col-lg-4 contact">
            {
              footer !== null &&
              <p>
                <span className="label">Address</span>
                <span>{footer[0].address}</span>
              </p>
            }            
          </div>
          <div className="col-lg-4 contact">
            {
              footer !== null &&
              <p>
                <span className="label">Phone</span>
                <span>
                  {
                    phone !== null &&
                    phone.map((info,key) => 
                      <React.Fragment key={key}>
                        <a href={"tel:"+info+""}>{info}</a>
                      </React.Fragment>
                    )
                  }
                </span>
              </p>
            }            
          </div>
          <div className="col-lg-4 contact">
            {
              footer !== null &&
              <p>
                <span className="label">Email</span>
                <span>
                  {
                    email !== null &&
                    email.map((info,key) => 
                      <React.Fragment key={key}>
                        <a href={"tel:"+info+""}>{info}</a>
                      </React.Fragment>
                    )
                  }
                </span>
              </p>
            }            
          </div>
        </section>
      </article>
      <hr/>
      <article className="container">
        <section className="copyright">
          <div className='row'>
            <div className='col-lg-4 order-md-2 order-lg-1'>
              <p>Copyright 2020. All Rights Reserved.</p>
            </div>
            <div className='col-lg-8 order-md-1 order-lg-2'>
              <ul className='general-info'>
                <li>
                  <a href={window.location.origin + '/about'}>{t('about_us')}</a>
                </li>
                <li>
                  <a href={window.location.origin + '/terms'}>Terms & Conditions</a>
                </li>
                <li>
                  <a href={window.location.origin + '/policy'}>Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </footer>
  )
}
