const axios = require('axios')
const config = require('./_config')
export const get_prodlist = (param) => {
return axios.get(`${config.default.url}/list/product`, {
  params: {
    ...param
  }
})
.then(({data}) => {
  let product = {}
  product.pages = data.data[0].pagecount
  product.items = data.data.slice(1, data.data.length)
  return product
})
.catch(() => "server error")
}
