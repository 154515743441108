import React, {useState} from 'react'
import _ from 'underscore'
import Spanner from '../spanner'
import data from '../../data'
import { useSelector, useDispatch } from 'react-redux'
import { ins, del } from '../../redux-actions/action'
// import { useTranslation } from 'react-i18next';

const { general, navlink } = data
export default function PriNav() {
  const [cartvisible, set_cartvisible] = useState(false)
  const [profilevisible, set_profilevisible] = useState(false)
  const cart = useSelector(state => state.cart)
  const profile = useSelector(state => state.login)
  const dispatch = useDispatch()
  let nested_qty = _.reduce(
    _.map(cart, data => data.product_uom_qty),
    (memo, qty) => memo + qty)
  return (
    <nav className="pc-prinav">
      <div className="container">
        <figure className="logo">
          <a href='/'>
            <img src={general.logo} alt={general.appname} />
          </a>
        </figure>
        <ul className="nav-link">
          <li>
            <a href='/gallery/latest_items'>Latest Products</a>
          </li>
          <li>
            <a href='/promodiscount'>Promotion & Discount</a>
          </li>
          <li>
            <a href='/gallery/hottest_items'>Hottest Items</a>
          </li>
          <li>
            <a href='/contactus'>Contact Us</a>
          </li>
          {
            profile === null &&
              <>
                <li>
                  <a href='/login'>SIGN IN</a>
                </li>
                <li>
                  <a href='/'>SIGN Up</a>
                </li>
              </>
          }
        </ul>
        {
          profile !== null &&
          (
            <>
              <div className='profile' onClick={() => set_profilevisible(!profilevisible)}>
                <p>S</p>
              </div>
              <div className={"profile-popup" + (profilevisible === true ? " active" : "")}>
                <ul className='profile-item'>
                  <li><a href='/profile'><img src={require('../../assets/img/user.svg')} alt="sb baby profile" />Profile</a></li>
                  <li><a href='#'><img src={require('../../assets/img/logout.svg')} alt="sb baby logout" />Logout</a></li>
                </ul>
              </div>
            </>
          )
        }
        <figure className="cart" onClick={() => set_cartvisible(!cartvisible)}>
          { nested_qty > 0 && <span className="badge">{nested_qty}</span>}
          <img src={require('../../assets/img/cart-green.png')} alt="sb baby cart" />
          <figcaption>My Cart</figcaption>
          
        </figure>
        <div 
          className={"cart-popup" + (cartvisible === true ? " active" : "")}
          >
            <h4 className="d-flex justify-content-between">My Cart <a href="/cart">view cart detail</a></h4>
            <hr/>
            <ul className="cart-item">
              {
                cart.map((item, key) =>{
                 return(
                  <li key={key}>
                      <span style={{
                        background: `url(${item.img}) no-repeat center / contain`
                      }} className="img"></span>
                      <span>{item.name.length > 6 ? item.name.slice(0, 6) + '...' : item.name }</span>
                      <Spanner value={item.product_uom_qty} prodId={item.product_id} attrId={item.varient_id} check_qty={true} change={(e) => {
                        let data = item
                        data.product_uom_qty = e
                        dispatch(ins(data))
                      }}/>
                      <span>{item.price * item.product_uom_qty}</span>
                      <img 
                      className="del-btn" 
                      src={require('../../assets/img/delete-button.png')}
                      onClick={() => dispatch(del(item))}
                      />
                  </li>
                )
                })
              }
            </ul>
          </div>
        <figure className="menu-bugger">
          <img src={require('../../assets/img/menu.png')} alt="sb baby open menu"/>
        </figure>

      </div>
    </nav>
  )
}
