import React from 'react'

// data
import SecNav from './_secnav'
import PriNav from './_prinav'

export default function Header() {
    return (
        <header>
            <SecNav />
            <PriNav />
        </header>
    )
}