import config from './_config'
const axios = require('axios')
// const data = {   
//   "user_id":2,
//   "sale_order_line": [
//     {
//         "variant_id": "3",
//         "product_id": 63,
//         "product_uom_qty": 1
//     }
//   ]
// }
export const post_payment = (order_id, acquirer_id, token) => {
  console.log(acquirer_id, order_id)
  return axios.create({
    headers: {
      'access-token': token
    }
  })
  .post(`${config.url}/paynow?order_id=${order_id}&acquirer_id=${acquirer_id}`)
  .then(res => res.data)
  .catch(err => err)
}