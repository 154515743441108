import PropTypes from 'prop-types'
import React from 'react'
import './style.scss'

export default function Promo({url, img}) {
  return (
    <a className='promotype' href={'/promolist/'+url+''} style={{background: "url("+ (img || require('../../assets/img/no-img.jpg')) + ") no-repeat center/cover"}}>
      <img src={('https://via.placeholder.com/24x24')} alt={img || ""} />
    </a>
  )
}

// Promo.propTypes = {
//   img: PropTypes.string.isRequired
// }