import config from './_config'
const axios = require('axios')
// const data = {   
//   "user_id":2,
//   "sale_order_line": [
//     {
//         "variant_id": "3",
//         "product_id": 63,
//         "product_uom_qty": 1
//     }
//   ]
// }
export const post_cart = (data, token) => {
  console.log(data, token)
  console.log(config.url)
  return axios.create({
    headers: {
      'access-token': token
    }
  })
  .post(`${config.url}/save/cart`, data)
  .then(res => res.data.result[0])
  .catch(err => err)
}