import { combineReducers } from 'redux'

import demo from './demo'
import cart from './cart'
import lang from './lang'
import login from './login'
import orderno from './orderno'

const STORE = combineReducers({
    demo,
    cart,
    lang,
    login,
    orderno
})

export default STORE