export const emit = data => ({ type: 'EMIT', data })

export const login_action = data => ({ type: 'LOGIN', data })

export const ins = data => ({ type: 'INS', data })

export const del = data => ({ type: 'DEL', data })

export const reset = data => ({ type: 'RESET', data })

export const order = data => ({ type: 'ORDER', data })