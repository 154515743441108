import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function Breadcrumb({pagename}) {
  const path = useLocation().pathname
  return (
    <section className="breadcrumb">
      <div className="container">
        <h2 className="title">{pagename}</h2>
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>{path.slice(1, path.length).replace(/-/ig, ' ')}</li>
        </ul>
      </div>
    </section>
  )
}
Breadcrumb.propTypes = {
  pagename: PropTypes.string.isRequired
}
