const axios = require('axios')
const config = require('./_config')
export const get_webcatedetail = (param) => {
return axios.get(`${config.default.url}/list/website/category/detail`, {
  params: {
    ...param
  }
})
.then((res) => {
  console.log(res.data.data)
  return res.data.data
})
.catch(() => "server error")
}