import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get_productqty } from '../../controller'

export default function Spanner({ value = 1, min = 1, change, prodId, attrId, check_qty }) {
  let [val, setvalue] = useState(value)
  
  // const check_product_qty = () => {
  //   if(check_qty){
  //     get_productqty({
  //       product_id: prodId,
  //       variant_id: attrId,
  //       qty: val
  //     }).then((res) => {

  //     })
  //   }
  // }
  
  return (
    <div className="qty-spanner">
      <input type="number" min={min} 
      disabled
      value={value}
      onChange={(e) => {
        setvalue(e.target.value === 0 ? 1 : e.target.value)
        change !== undefined && change(e.target.value === 0 ? 1 : e.target.value)
      }}
      />
      <div>
        <button
          onClick={() => {
            let qty = Number(val) + 1
            setvalue(qty)
            change !== undefined && change(qty)
          }}
        ></button>
        <button
          onClick={() => {
            if(val > 1) {
              let qty = Number(val) - 1
              setvalue(qty)
              change !== undefined && change(qty)
            }
            }}
        ></button>
      </div>
    </div>
  )
}
Spanner.propTypes = {
  value: PropTypes.number
}

