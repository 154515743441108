import React, { useState, useEffect } from 'react'
import './style.scss'
export default function Loading() {
  const [state, setstate] = useState('')
  useEffect(() => {
    setTimeout(function() {
      setstate(
        state.length > 2 ? '' : state + '.'
      )
    }, 600)
  }, [state])
  return (
    <div className="loader">
      {/* <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <br/> */}
      <p className="text-secondary">loading data <span className="loading-text"> {state}</span></p>
    </div>
  )
}
