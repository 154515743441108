import { get_profile } from '../controller'
const login = (state = JSON.parse(localStorage.getItem('login')), action) => {
  switch (action.type) {
    case 'LOGIN' :
      let getdata = async () => await get_profile(
        action.data.uid, 
        action.data.token
      )
      getdata().then(res => {
        let loginData = {
          ...res,
          userid: action.data.uid,
          access_token: action.data.token,
          expire: action.data.expires
        }
        localStorage.setItem('login', JSON.stringify(loginData))
        window.location.href = '/'
      })
      return state
    default :
      return state
  }
}

export default login