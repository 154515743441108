const axios = require('axios')
const config = require('./_config')

export const get_exit_wish_list = (param, access_token) => {
    return axios.get(`${config.default.url}/exist/wishlist`,{
        headers: {
          'access-token':  'access_token_2c4ab69bec0dee2d36023d8b3c0c803f197ef328'
        },
        params: {
            ...param
        }
    }).then((res) => res.data.data)
        .catch(() => "server error")
}
