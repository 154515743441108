const axios = require('axios')
const config = require('./_config')

// export const post_login = (name,pass) => {
//     return axios.get(`${config.default.url}/api/auth/token`,{
//         headers: {
//           db: config.default.db,
//           login: name,
//           password: pass
//         }
//     }).then((res) => console.log(res))
//         .catch(() => "server error")
// }

export const post_login = ({name,pass}) => {
  return axios.create({
    headers: {
      db: config.default.db,
      login: name,
      password: pass
    }
  })
  .get(`${config.default.url}/api/auth/token`)
  .then(res => res.data)
  .catch(() => "server error")
}