import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { emit } from '../../redux-actions/action'
// import { useHistory } from "react-router";
import data from '../../data'
const { languages, socials } = data
export default function SecNav() {
  const [ openSearch, setSearchState ] = useState(false)
  const [ name, setName ] = useState('');
  const defaultLang = useSelector(state => state.lang)
  const handleSubmit = (e) => {
    e.preventDefault();
    let searchname = name.replace(/[^a-zA-Z ]/g, "");
    if (searchname.length !== 0) {
      window.location.href = window.location.origin + `/productsearchresult/${searchname}`
    }
  }
  let dispatch = useDispatch()
  const handleChange = (e) => {
    const langType = e.target.value;
    dispatch(emit(langType))
    window.location.reload();
  }
  
  return (
    <React.Fragment>
      <form className={"mb-search container" + (openSearch && " active")} onSubmit={handleSubmit}>
        <input 
        type="search" 
        placeholder="search product ..."
        value={name}
        onChange={e => setName(e.target.value)}
        />
        <button>Search</button>
      </form>

      <nav className="pc-secnav">
        <div className="container">
          <ul className="socials">
            {
              socials.map((link, key) => (
                <li key={key}>
                  <a href={link.url}>
                    <img src={link.img} alt={link.img} />
                  </a>
                </li>
              ))
            }
          </ul>
          <div className="pc-option">
            <form onSubmit={handleSubmit}>
              <input type="text" placeholder="search product ..." value={name} onChange={e => setName(e.target.value)}/>
              <img src={require('../../assets/img/search.png')} alt={"search sb baby"}/>
            </form>
            <img
              src={require('../../assets/img/search.png')}
              className="mb-reqsearch"
              alt="sbbaby search product"
              onClick={() => {
                setSearchState(!openSearch)
              }}
            />
            <select 
              className="langs" 
              onChange={handleChange}
              defaultValue={defaultLang}
            >
              <option value="en">{languages.en.toUpperCase()}</option>
              <option value="mm">{languages.mm.toUpperCase()}</option>
            </select>
          </div>

        </div>
      </nav>

    </React.Fragment>
  )
}