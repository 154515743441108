import _ from 'underscore'
const cart = (state = localStorage.getItem('cart') === null ? [] : JSON.parse(localStorage.getItem('cart')), action) => {
  switch (action.type) {
      case 'INS':
            let data = state
            let products = data.filter(i => i.product_id !== action.data.product_id)
            products.push(action.data)
            localStorage.setItem('cart', JSON.stringify(_.sortBy(products, 'product_id')))
            return _.sortBy(products, 'product_id')
      case 'DEL':
          let del = state.filter(i => i !== action.data)
          localStorage.setItem('cart', JSON.stringify(del))
          return del
      case 'RESET':
        localStorage.setItem('cart', JSON.stringify([]))
        return []
      default :
          return state
  }
}

export default cart