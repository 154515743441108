import { BrowserRouter, Route, Switch } from 'react-router-dom'
import React, {Suspense, lazy, createContext} from 'react'
import Loading from '../components/loading'
import PromoDiscount from '../layout/promodiscount'// custom component
import Header from '../components/header'
import Footer from '../components/footer'
const Home = lazy(() => import('../layout/home/Home'))
const ShoppingCart = lazy(() => import('../layout/shoppingcart'))
const ProductDetail = lazy(() => import('../layout/product-detail'))
const About = lazy(() => import('../layout/about'))
const Policy = lazy(() => import('../layout/policy'))
const Terms = lazy(() => import('../layout/terms'))
const NotFound = lazy(() => import('../layout/404'))
const Login = lazy(() => import('../layout/login'))
const Contactus = lazy(() => import('../layout/contactus'))
const Profile = lazy(() => import('../layout/profile'))
const Brand = lazy(() => import('../layout/brandlisting'))
const Edituserprofile = lazy(() => import('../layout/edituserprofile'))
const Productsearchresult = lazy(() => import('../layout/productsearchresult'))
const Gallery = lazy(() => import('../layout/gallery'))
const Orderdetail = lazy(() => import('../layout/orderdetail'))
const Wishlist = lazy(() => import('../layout/wishlist'))
const Promolist = lazy(() => import('../layout/promolist'))
const Promodiscount = lazy(() => import('../layout/promodiscount'))

export default function AppRoute() {
    return (
        <BrowserRouter>
            <Header />
            <Suspense fallback={<Loading />}>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/cart' component={ShoppingCart} />
                    <Route exact path='/saitest' component={Home} />
                    <Route exact path='/login' component={Login} />
                    <Route exact path='/product/:productid/:productname' component={ProductDetail} />
                    <Route exact path='/about' component={About} />
                    <Route exact path='/policy' component={Policy} />
                    <Route exact path='/terms' component={Terms} />
                    <Route exact path='/contactus' component={Contactus} />
                    <Route exact path='/Profile' component={Profile} />
                    <Route exact path='/brandlisting/:brandid/:brandname' component={Brand} />
                    <Route exact path='/edituserprofile' component={Edituserprofile} />
                    <Route exact path='/productsearchresult/:search' component={Productsearchresult} />
                    <Route exact path='/gallery/:galleryid' component={Gallery} />
                    <Route exact path='/wishlist' component={Wishlist} />
                    <Route exact path='/orderdetail/:orderid' component={Orderdetail} />
                    <Route exact path='/promolist/:promoid' component={Promolist} />
                    <Route exact path='/promodiscount' component={Promodiscount} />
                    <Route component={NotFound}/>
                </Switch>
            </Suspense>
            <Footer />
        </BrowserRouter>
    )
}
